import { UploadMediaResponseT } from './media';
import { ProductT, Visibility } from './product';
import { StatusLevelT } from './statusLevel';
import { IconT } from '../../modules/designSettings/types';
import { BONUS_PROLONGATION, FinderProduct, SelectionType } from './entities';
import { ButtonFormType } from '../models/Form';

export type EndUserLogInPayloadT = {
  email: string;
  password: string;
};

export type Icon = {
  id: number;
  s3Url: string;
  originalFileName: string;
  targetType: string;
  targetId: number;
  svgContent: string;
  cdnUrl: string;
  new: boolean;
};

export type Category = {
  id: number;
  name: string;
  activated: boolean;
  projectId: number;
  demandAreaId: number;
  priority: { number: number };
  products: ProductT[];
  description: string;
  selectionType: SelectionType;
  icon: Icon;
  covered?: boolean;
  points?: number;
  categoryColor?: string;
  visibility: Visibility | null;
  isAddedToWishlist?: boolean;
};

export type DemandArea = {
  id: number;
  name: string;
  activated: boolean;
  position: { number: number };
  projectId: number;
  color: string;
  categories: Category[];
  icon: UploadMediaResponseT;
};

type User = {
  id: number;
  pseudoCode: string;
  projectId: number;
  email: string;
  passwordHash: string;
  activated: boolean;
  agreeUseName: boolean;
  statusLevelId: number | null;
  congratulation: boolean | null;
  birthDate: string | null;
  postalCode: string | null;
  district: string;
};

type Tenant = {
  tenantBrandId: number;
  identifier: string;
  projectsTotal: number;
  id: number;
  databaseUrl: string;
  dbName: string;
  userLogin: string;
  password: string;
  name: string;
  activated: boolean;
  brandKit: {
    id: number;
    name: string;
  };
  comment: string;
  domains: {
    id: number;
    name: string;
  }[];
  clientName: string;
  clientAddress: string;
  clientAddressLine2: string;
  clientEmail: string;
  clientCity: string;
  plz: string;
  clientPhone: string;
  logo: Icon;
  hilfeUndKontakt: string;
  impressum: string;
  dataProtection: null | string;
  agb: null | string;
};

type Project = {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  id: number;
  name: string;
  contractStart: string;
  contractEnd: string;
  contactSKP: string;
  comment: string;
  template: {
    id: number;
    name: string;
  };
  status: string;
  editing: boolean;
  activated: boolean;
  domain: string;
  subdomain: string;
  url: string;
  publishingDate: string;
  pointUnits: string;
  maxPoints: number;
  pointsIcon: IconT;
  reportTime: string;
  dueDate: string;
  currentDueDate: string;
  bonusCycle: BONUS_PROLONGATION;
};

export type EndUserDataT = {
  invisibleCategories: Category[]; // TODO remove invisibleCategories from store
  listDemandArea: DemandArea[];
  user: User;
  tenant: Tenant;
  project: Project;
};

export enum PRODUCT_RECOMMENDATION_RULES {
  PRIORITY = 'priority',
  CRITERIA = 'criteria',
  PRODUCT = 'product',
}

export type EndUserStatusLevelResponseT = {
  userPoints: number; // TODO mb should be removed
  statusLevelDtoPage: {
    content: StatusLevelT[];
  };
};

export enum RuleActionStatus {
  FROM = '-locked',
  TO = '=locked',
}

export type EndUserRuleActionStatusLevelResponseT = {
  id: number;
  endUserId: number;
  projectId: number;
  status: RuleActionStatus;
  statusLevelId: number;
};

export type UpdateWishlistMutation = {
  projectId: number;
  productId: number;
  tenantId: string;
};

export type GetEndUserTenantAndProjectT = {
  id: number;
  domain: string;
  subDomain: string;
  tenantId: number;
  projectId: number;
};

export type EndUserPublicDemandAreasT = EndUserDataT;

export type DomainT = {
  name: string;
  id: number;
};

export enum FormTypeProduct {
  COVERAGE_INDICATION = 'COVERAGE_INDICATION',
  PRODUCT_CONTACT = 'PRODUCT_CONTACT',
  FOOTER_CONTACT = 'FOOTER_CONTACT',
  REQUEST_PRODUCT_SWITCH = 'REQUEST_PRODUCT_SWITCH',
}

export type ContactFormBody = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  products?: number[];
  contractNumber?: number;
  invoiceNumber: string;
  contactFormYourMessage: string;
  buttonFormType: FormTypeProduct;
};

export type ContactFormMutation = {
  isGuestMode: boolean;
  projectId: number;
  tenantId: string;
  body: ContactFormBody;
};

export enum PUBLIC_MODEL_STATUS {
  PUBLISHED = 'PUBLISHED',
  NOT_PUBLISHED = 'NOT_PUBLISHED',
}

export type EndUserPublicModelT = {
  name: string;
  status: PUBLIC_MODEL_STATUS;
  activated: boolean;
  pointUnits: string;
  maxPoints: number;
  pointsIcon: IconT;
  currentDueDate: string;
};

export type UserStatusLevelT = {
  currentPoints: number;
  currentPointsInWishlist: number;
  wishedPoints: number;
};

export type RuleActionStatusLevelT = {
  maxAvailablePoints: number | null;
  extraPoints: number | null;
};

export type UpdateUserDataPayloadT = {
  agreeUseName: boolean;
  agreeExpandUserExperience: boolean;
  agreeInformByEmail?: boolean;
  agreeInformByPhone?: boolean;
  agreeInformByPost?: boolean;
  tenantId?: number;
};

export type EndUserGuestWishlistProduct = {
  id: number;
  projectId: number;
  endUserId: number;
  productId: number;
  special: boolean;
  product: ProductT;
};

// Bonus catalog for rule bonuses

type Picture = {
  id: number;
  s3Url: string;
  originalFileName: string;
  targetType: string;
  targetId: number;
  svgContent: string;
  cdnUrl: string;
  new: boolean;
};

export type BonusCatalogItemT = {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  id: number;
  name: string;
  activated: boolean;
  projectId: number;
  picture: Picture;
  title: string;
  description: string;
  donation: boolean;
  payoutType: string;
  payoutOption: string;
};

export type GetFinderRecommendationsT = {
  recommendedCategoryId: number;
  recommendations: FinderProduct[];
};
