import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import { Form, FormInstance } from 'antd';
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from '../../../../lib/utils/regexp';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { AppCheckbox } from '../../../../components/AppCheckox';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import TextArea from 'antd/lib/input/TextArea';
import cn from 'classnames';
import { FormTypeProduct } from '../../../../lib/types/endUser';
import { useAppSelector } from '../../../../lib/store/hooks';

type Props = {
  form: FormInstance;
  //eslint-disable-next-line
  onFinish: (arg: any) => Promise<void>;
  isChecked: boolean;
  toggleCheckbox: () => void;
  formType: FormTypeProduct;
};

const contactForm = 'endUser.contactForm';

export const EndUserContactForm: FC<Props> = ({ form, onFinish, isChecked, toggleCheckbox, formType }) => {
  const { t } = useTranslation();

  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  const clientName = demandArea?.tenant?.clientName;

  return (
    <Form form={form} onFinish={onFinish} className={styles.form}>
      <div className={styles.formInputsRow}>
        <FormItem
          name={FormItemName.FIRST_NAME}
          hasFeedback
          rules={[
            {
              required: true,
              message: t(`${contactForm}.firstName.error`),
            },
            { max: 100 },
          ]}
        >
          <FormInput label={t(`${contactForm}.firstName.label`)} />
        </FormItem>

        <FormItem
          name={FormItemName.LAST_NAME}
          hasFeedback
          rules={[
            {
              required: true,
              message: t(`${contactForm}.lastName.error`),
            },
            { max: 100 },
          ]}
        >
          <FormInput label={t(`${contactForm}.lastName.label`)} />
        </FormItem>
      </div>
      <div className={styles.formInputsRow}>
        <FormItem
          name={FormItemName.EMAIL}
          hasFeedback
          rules={[
            { required: true, message: t(`${contactForm}.email.errorEmpty`) },
            {
              pattern: EMAIL_PATTERN,
              message: t(`${contactForm}.email.errorValid`),
            },
          ]}
        >
          <FormInput label={t(`${contactForm}.email.label`)} />
        </FormItem>

        <FormItem
          name={FormItemName.PHONE_NUMBER}
          hasFeedback
          rules={[
            { required: true, message: t(`${contactForm}.phone.errorEmpty`) },

            {
              pattern: PHONE_NUMBER_PATTERN,
              message: t(`${contactForm}.phone.errorEmpty`),
            },
          ]}
        >
          <FormInput label={t(`${contactForm}.phone.label`)} maxLength={12} />
        </FormItem>
      </div>
      {formType === FormTypeProduct.REQUEST_PRODUCT_SWITCH && (
        <>
          <div className={styles.formInputsRow}>
            <FormItem
              name={FormItemName.CONTRACT_NUMBER}
              hasFeedback
              rules={[
                { required: true, message: t(`${contactForm}.contractNumber.errorEmpty`) },

                {
                  pattern: PHONE_NUMBER_PATTERN,
                  message: t(`${contactForm}.contractNumber.errorEmpty`),
                },
              ]}
            >
              <FormInput label={t(`${contactForm}.contractNumber.label`)} maxLength={12} />
            </FormItem>
          </div>
          <div className={cn(styles.formInputsRow, styles.textAreaRow2)}>
            <FormItem
              name={FormItemName.CONTACT_FORM_MESSAGE}
              hasFeedback
              rules={[{ required: true, message: t(`${contactForm}.yourMessage.errorEmpty`) }]}
              className={styles.textArea}
            >
              <FormInput
                label={t(`${contactForm}.yourMessage.label`)}
                labelClass={styles.inputLabel}
                showCount
                autoSize={{ minRows: 5, maxRows: 5 }}
                as={TextArea}
                className={styles.inputTextArea}
                maxLength={500}
              />
            </FormItem>
          </div>

          <div className={styles.formInputsRow}>
            <AppCheckbox onChange={toggleCheckbox} checked={isChecked} className={styles.checkbox} />

            <EndUserTypography type="headlineSeventh" onClick={toggleCheckbox}>
              Ich bin damit einverstanden, dass meine angegebenen Daten von der {clientName} zur
              Angebotserstellung und Kontaktaufnahme für einen Tarifabschluss/-wechsel verwendet werden
              dürfen.*
            </EndUserTypography>
          </div>
        </>
      )}
      {formType === FormTypeProduct.FOOTER_CONTACT && (
        <div className={cn(styles.formInputsRow, styles.textAreaRow)}>
          <FormItem
            name={FormItemName.CONTACT_FORM_MESSAGE}
            hasFeedback
            rules={[{ required: true, message: t(`${contactForm}.yourMessage.errorEmpty`) }]}
            className={styles.textArea}
          >
            <FormInput
              labelClass={styles.inputLabel}
              showCount
              autoSize={{ minRows: 5, maxRows: 5 }}
              as={TextArea}
              label={t(`${contactForm}.yourMessage.label`)}
              maxLength={500}
              className={styles.inputTextArea}
            />
          </FormItem>
        </div>
      )}
      {formType === FormTypeProduct.COVERAGE_INDICATION && (
        <>
          <div className={styles.formInputsRow}>
            <FormItem
              name={FormItemName.INVOICE_NUMBER}
              hasFeedback
              rules={[
                { required: true, message: t(`${contactForm}.invoiceNumber.errorEmpty`) },

                {
                  pattern: PHONE_NUMBER_PATTERN,
                  message: t(`${contactForm}.invoiceNumber.errorEmpty`),
                },
              ]}
            >
              <FormInput label={t(`${contactForm}.invoiceNumber.label`)} maxLength={12} />
            </FormItem>
          </div>
          <div className={cn(styles.formInputsRow, styles.textAreaRow2)}>
            <FormItem
              name={FormItemName.CONTACT_FORM_MESSAGE}
              hasFeedback
              rules={[{ required: true, message: t(`${contactForm}.yourMessage.errorEmpty`) }]}
              className={styles.textArea}
            >
              <FormInput
                label={t(`${contactForm}.yourMessage.label`)}
                labelClass={styles.inputLabel}
                showCount
                autoSize={{ minRows: 5, maxRows: 5 }}
                as={TextArea}
                className={styles.inputTextArea}
                maxLength={500}
              />
            </FormItem>
          </div>

          <div className={styles.formInputsRow}>
            <AppCheckbox onChange={toggleCheckbox} checked={isChecked} className={styles.checkbox} />

            <EndUserTypography type="headlineSeventh" onClick={toggleCheckbox}>
              Ich bin einverstanden damit, dass meine angegebenen Daten von der EVI Energieversorgung GmbH zum
              Datenabgleich für den EVI HausVersorger und die Dauer der Teilnahme verwendet werden, um meine
              korrekten Punkte anzuzeigen.*
            </EndUserTypography>
          </div>
        </>
      )}
    </Form>
  );
};
