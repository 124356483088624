export enum FormItemName {
  USERNAME = 'username',
  EMAIL = 'email',
  REGISTRATION_EMAIL = 'registrationEmail',
  PASSWORD = 'password',
  REGISTRATION_PASSWORD = 'registrationPassword',
  REPEAT_PASSWORD = 'repeatPassword',
  FULL_NAME = 'fullName',
  TENANT_LIST = 'tenants',
  RIGHTS = 'rights',
  LANGUAGE = 'language',
  INCLUDE_ALL_CURRENT_AND_FUTURE_TENANTS = 'allAdminTenants',
  PROJECT_NAME = 'modelName',
  SELECTED_TEMPLATE = 'selectedTemplate',
  CREATION_DATE = 'creationDate',
  META_TITLE = 'metTitle',
  META_DESCRIPTION = 'metaDescription',
  SUBDOMAIN = 'subdomain',
  DOMAIN = 'domain',
  DOMAINS = 'domains',
  MAIL_SERVER = 'mailServer',
  MAIL_SERVER_PASSWORD = 'mailServerPassword',
  MAIL_SERVER_HOST = 'mailServerHost',
  MAIL_SERVER_PORT = 'mailServerPort',
  MAIL_SERVER_USER_NAME = 'mailServerUsername',
  MAIL_SERVER_CONTACT_EMAIL = 'mailServerContactEmail',
  MAIL_SERVER_SENDER = 'mailServerSender',
  TRACKING = 'tracking',
  TRACKING_SOFTWARE = 'trackingSoftware',
  TRACKER_URL = 'trackerUrl',
  TRACKING_TRACKER_SITE_ID = 'trackingTrackerSiteId',
  DATA_PROTECTION = 'notificationEnabled',
  DATA_PROTECTION_HEAD = 'headline',
  DATA_PROTECTION_SUB = 'subline',
  DATA_PROTECTION_CONTENT = 'content',
  FILE_UPLOAD_LIST = 'fileUploadList',
  FILE_UPLOAD_ITEM = 'fileUploadItem',
  FILE_UPLOAD_URL = 'fileUploadUrl',
  FAVICON = 'favicon',
  CONTRACT_DATES = 'contractDates',
  TENANT_NAME = 'name',
  COMMENTS = 'comment',
  CLIENT_NAME = 'clientName',
  CLIENT_ADDRESS = 'clientAddress',
  ADDRESS_LINE_TWO = 'clientAddressLine2',
  PLZ = 'plz',
  CLIENT_CITY = 'clientCity',
  CLIENT_EMAIL = 'clientEmail',
  CLIENT_PHONE = 'clientPhone',
  USERS = 'users',
  PROJECT_URL = 'projectUrl',
  LEGAL_NOTICE = 'impressum',
  HELP_AND_CONTACT = 'hilfeUndKontakt',
  DATA_PROTECTION_URL = 'dataProtection',
  CLIENT_WEBSITE = 'clientWeb',
  BUTTON_LINKS = 'buttonLinks',
  LINK_NAME = 'linkName',
  LINK_URL = 'linkUrl',
  NAVIGATION = 'navigation',
  NAVIGATION_LANDING = 'landingPage',
  NAVIGATION_DEMAND = 'demandAreas',
  NAVIGATION_BONUSES = 'bonuses',
  NAVIGATION_WISHLIST = 'wishlist',
  NAVIGATION_POINT = 'pointOverview',
  NAME = 'name',
  TEMPLATE = 'template',
  CONTACT_SKP = 'contactSKP',
  CONTRACT_START = 'contractStart',
  CONTRACT_END = 'contractEnd',
  CREATED_DATE = 'createdDate',
  USER_VISIBILITY_CREATION_DATE = 'creationDateForUser',
  DESCRIPTION = 'description',
  DEMAND_AREA = 'demandArea',
  PRIORITY = 'priority',
  SELECTION_TYPE = 'selectionType',
  ICON = 'icon',
  PRODUCT_ID = 'productId',
  ACTIVATED = 'activated',
  STATUS_COMMENT = 'statusComment',
  CATEGORY = 'category',
  VISIBILITY = 'visibility',
  SCORING_RANGES = 'scoringRanges',
  POINTS = 'points',
  RECURRING_POINTS = 'recurringPoints',
  COVERAGE_AMOUNT = 'coverageAmount',
  AGE_RANGE = 'ageRange',
  RANGES = 'ranges',
  AGE_RANGE_COVERAGE = 'ageRangeCoverage',
  FROM = 'from',
  TO = 'to',
  REGION = 'region',
  DISTRICT = 'district',
  DISTRICTS = 'districts',
  REGIONS = 'regions',
  REGION_COVERAGE = 'regionCoverage',
  OPERATOR = 'operator',
  DISTRICT_COVERAGE = 'districtCoverage',
  SUBLINE = 'subline',
  PRICE = 'price',
  BADGE_ID = 'badgeId',
  ASSOCIATED_PRODUCT = 'associatedProduct',
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  PRICE_DESCRIPTION = 'priceDescription',
  LINK = 'link',
  PICTURE = 'picture',
  PRIMARY_BUTTON = 'primaryButton',
  SECONDARY_BUTTON = 'secondaryButton',
  BULLET_POINTS = 'bulletPoints',
  IS_BUTTON_ENABLED = 'isButtonEnabled',
  OPERATION = 'operation',
  TEXT = 'text',
  FORM = 'FORM',
  DUE_DATE = 'dueDate',
  CYCLE = 'cycle',
  AGB = 'agb',

  // status level configurator
  STATUS_LEVEL_TITLE = 'statusLevelTitle',
  SUBLEVELS = 'sublevels',
  SUBLEVEL_BONUSES = 'sublevelBonuses',
  SUBLEVEL_TITLE = 'sublevelTitle',
  SUBLEVEL_POINTS = 'sublevelPoints',
  //
  STATUS_LEVEL_UNIT = 'statusLevelUnit',
  STATUS_LEVEL_ICON = 'statusLevelIcon',
  STATUS_LEVEL_MAX_POINTS = 'statusLevelMaxPoints',

  // endUser
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  INVOICE_NUMBER = 'invoiceNumber',
  CONTRACT_NUMBER = 'contractNumber',
  AGREE_USE_NAME = 'agreeUseName',
  AGREE_EXPAND_USER_EXPERIENCE = 'agreeExpandUserExperience',
  AGREE_INFORM_BY_PHONE = 'agreeInformByPhone',
  AGREE_INFORM_BY_EMAIL = 'agreeInformByEmail',
  AGREE_INFORM_BY_POST = 'agreeInformByPost',
  READ_TERMS_AND_PRIVACY_POLICY = 'readTermsAndPrivacyPolicy',
  PSEUDONYM = 'pseudonym',
  CONTACT_FORM_MESSAGE = 'contactFormYourMessage',

  // rule builder
  RULE_NAME = 'ruleName',
  RULE_TYPE = 'ruleType',

  // finder

  FINDER_ENABLED = 'finderEnabled',
  FINDER_URL = 'finderUrl',
  FINDER_BUTTON_NAME = 'finderButtonName',
}

export enum ButtonFormType {
  COVERAGE_INDICATION = 'coverageIndication',
  PRODUCT_CONTACT = 'productContact',
  REQUEST_PRODUCT_SWITCH = 'requestProductSwitch',
}
