import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { auth } from './authSlice';
import { api } from '../api';
import { tenants } from './tenantsSlice';
import { userManagement } from './userManagementSlice';
import { designSettings } from './designSettingsSlice';
import { projects } from './projectsSlice';
import { products } from './productsSlice';
import { demandAreas } from './demandAreasSlice';
import { productCategories } from './productCategoriesSlice';
import { bonusCatalog } from './bonusCatalogSlice';
import { statusLevel } from './statusLevelSlice';
import { endUser } from './endUserSlice';
import { containerQueries } from './containerQueriesSlice';
import { ruleBuilder } from './ruleBuilder';
import { RootState } from '../index';
import { logoutAction } from '../actions';
import { AppLocalStorage } from '../../utils/helpers';
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../utils/constants';
import { productForm } from './ProductFormSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'endUser'],
};

export const allReducers = combineReducers({
  auth,
  tenants,
  userManagement,
  designSettings,
  projects,
  demandAreas,
  productCategories,
  products,
  bonusCatalog,
  statusLevel,
  ruleBuilder,
  endUser,
  containerQueries,
  productForm,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === logoutAction.type) {
    state = undefined;
    AppLocalStorage.removeItem('persist:root');
    AppLocalStorage.removeItem(ACCESS_TOKEN_NAME);
    AppLocalStorage.removeItem(REFRESH_TOKEN_NAME);
  }

  return allReducers(state, action);
};
export default persistReducer(rootPersistConfig, rootReducer);
