import { ButtonFormType } from '../../models/Form';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ProductFormSliceT = {
  productButtonSelected: ButtonFormType | null;
};

const initialState: ProductFormSliceT = {
  productButtonSelected: null,
};

const productFormSlice = createSlice({
  name: 'productForm',
  initialState,
  reducers: {
    setSelectedProductButton: (state, action: PayloadAction<ButtonFormType | null>) => {
      state.productButtonSelected = action.payload;
    },
  },
});

export const { setSelectedProductButton } = productFormSlice.actions;
export const productForm = productFormSlice.reducer;
