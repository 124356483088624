import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ModalActions } from '../../models/Modal';
import { TenantT } from '../../types/tenants';
import { TableSortOrder } from '../../utils/constants';
import { tenantsApi } from '../api/tenants';
import { Filter, FiltersPayload, Sort } from '../../types/filteringAndSort';
import { TENANT_COLUMN } from '../../../modules/tenants/helpers/constants';

type SliceStateT = {
  page: number;
  arrangeId: TENANT_COLUMN | null;
  sorting: Sort;
  filtering: Filter;
  modalType: ModalActions | null;
  isModalOpen: boolean;
  isUploadModalOpen: boolean;
  selectedTenant: TenantT | null;
};

const initialState: SliceStateT = {
  page: 1,
  isModalOpen: false,
  isUploadModalOpen: false,
  modalType: null,
  selectedTenant: null,
  arrangeId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  filtering: {
    filterFieldName: null,
    filter: [],
  },
};

export type TenantFiltersPayload = FiltersPayload & {
  arrangeId: TENANT_COLUMN;
};

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    openTenantModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload.modalType;
      state.selectedTenant = action.payload.selectedTenant;
    },
    closeTenantModal: (state) => {
      state.isModalOpen = false;
      state.modalType = null;
      state.selectedTenant = null;
    },
    openUploadFileModal: (state, action: PayloadAction<{ selectedTenant: TenantT }>) => {
      state.isUploadModalOpen = true;
      state.selectedTenant = action.payload.selectedTenant;
    },
    closeUploadFileModal: (state) => {
      state.isUploadModalOpen = false;
      state.selectedTenant = null;
    },
    setTenantTableFilters: (state, action: PayloadAction<TenantFiltersPayload>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
      state.filtering = action.payload.filtering;
    },
    setTenantTablePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetTenantTable: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(tenantsApi.endpoints.editTenant.matchFulfilled, (state) => {
        state.isModalOpen = false;
        state.modalType = null;
        state.selectedTenant = null;
      })
      .addMatcher(tenantsApi.endpoints.addTenant.matchFulfilled, (state) => {
        state.isModalOpen = false;
        state.modalType = null;
      })
      .addMatcher(tenantsApi.endpoints.deleteTenant.matchFulfilled, (state) => {
        state.isModalOpen = false;
        state.modalType = null;
      });
  },
});

export const {
  openTenantModal,
  closeTenantModal,
  openUploadFileModal,
  closeUploadFileModal,
  setTenantTableFilters,
  setTenantTablePage,
  resetTenantTable,
} = tenantsSlice.actions;
export const tenants = tenantsSlice.reducer;
