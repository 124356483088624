import { FC } from 'react';
import cx from 'classnames';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormInput } from '../FormInput';
import { DatePickerHeader } from './components/DatePickerHeader';

import { ReactComponent as CalendarIcon } from './../../assets/icons/calendar-icon.svg';

import styles from './index.module.scss';

//eslint-disable-next-line
type Props = any;

export const DatePicker: FC<Props> = ({
  style,
  suffix,
  popperClassName,
  value,
  size,
  hasFeedback,
  inputClassName,
  inputRootClassName,
  iconClassName,
  showCalendarIcon,
  clearButtonClassName,
  locale,
  ...rest
}) => {
  return (
    <ReactDatePicker
      popperClassName={cx(styles.root, popperClassName)}
      showPopperArrow={false}
      clearButtonClassName={clearButtonClassName}
      customInput={
        <FormInput
          rootClassname={inputRootClassName}
          className={cx(styles.inputDate, inputClassName)}
          style={style}
          suffix={suffix}
          size={size}
          icon={showCalendarIcon ? <CalendarIcon className={iconClassName} /> : undefined}
          hasFeedback={hasFeedback}
        />
      }
      renderCustomHeader={(props) => <DatePickerHeader {...props} locale={locale} />}
      value={value}
      dateFormat="dd.MM.yyyy"
      locale={locale}
      {...rest}
    />
  );
};
