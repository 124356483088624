import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Button, Form, Input, message } from 'antd';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import { EndUserButton } from '../../../../components/EndUserButton';
import { EndUserLogInPayloadT } from '../../../../lib/types/endUser';
import { useLazyGetEndUserDataQuery, useLogInMutation } from '../../../../lib/store/api/auth';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { EndUserAuthBottomButtons } from '../../../../components/EndUserAuthBottomButtons';
import { FC, useEffect, useState } from 'react';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useNavigate } from 'react-router-dom';
import { setMatomoTrackingForGuest } from '../../../../lib/store/slices/endUserSlice';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import cn from 'classnames';
import { EndUserVideoFrameModal } from '../EndUserVideoFrameModal';
import { SectionName } from '../../../../lib/models/Section';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';

const inputErrorProps = {
  validateStatus: 'error',
};

const loginCardT = 'endUser.loginCard';
const loginFormItems = 'endUser.loginCard.formItems';
const backendErrors = 'endUser.loginCard.errors';

type Props = {
  applyNewDesign: boolean;
};

export const EndUserLoginCard: FC<Props> = ({ applyNewDesign }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const { tenantAndProject, designSettings, sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const [logIn, { error, reset }] = useLogInMutation();
  const [getEndUserData] = useLazyGetEndUserDataQuery();

  const loginSectionContent = sections?.[SectionName.HOUSE_MODEL];

  const youtubeVideoId = loginSectionContent?.youtubeVideoId;
  const youtubeVideoTitle = loginSectionContent?.youtubeVideoTitle;

  const email = Form.useWatch(FormItemName.EMAIL, form);
  const password = Form.useWatch(FormItemName.PASSWORD, form);

  const handleVideoModalOpen = () => {
    setIsVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setIsVideoModalOpen(false);
  };

  const onFinish = async (formValues: Record<string, string>) => {
    const formattedFormValues = {
      [FormItemName.EMAIL]: formValues[FormItemName.EMAIL].trim(),
      [FormItemName.PASSWORD]: formValues[FormItemName.PASSWORD].trim(),
    } as EndUserLogInPayloadT;

    let data = null;

    try {
      data = await logIn({
        username: formattedFormValues.email,
        password: formattedFormValues.password,
        isEndUser: true,
        tenantId: String(tenantAndProject?.tenantId),
      }).unwrap();

      dispatch(setMatomoTrackingForGuest({ trackingEnabled: false }));
      //eslint-disable-next-line
    } catch (error: any) {
      let errorMessage = error.data.detail || error.data.message || t(`${backendErrors}.somethingWentWrong`);

      if (errorMessage === 'Bad credentials') {
        errorMessage = t(`${backendErrors}.badCredentials`);
      }

      messageApi.error(errorMessage);
    }

    if (data) {
      getEndUserData({ tenantId: String(tenantAndProject?.tenantId) });
    }
  };

  const logoS3Url = designSettings?.headerConfig?.logo?.s3Url;

  const onClickForgotPassword = () => {
    navigate(ROUTER_PATHS.passwordReset, { replace: true });
  };
  const onClickSignUp = () => {
    const path = isFrenchTenant ? ROUTER_PATHS.signUpFr : ROUTER_PATHS.signUp;

    navigate(path, { replace: true });
  };

  const onClickContinueAsGuest = () => {
    navigate(`/${ROUTER_PATHS.terms}`);
  };

  useEffect(() => {
    reset();
    //eslint-disable-next-line
  }, [email, password]);

  return (
    <>
      {contextHolder}
      <EndUserVideoFrameModal
        isModalOpen={isVideoModalOpen}
        close={handleVideoModalClose}
        youtubeVideoId={youtubeVideoId}
        youtubeVideoTitle={youtubeVideoTitle}
      />
      <div className={cn(styles.container, applyNewDesign && styles.containerWithNewDesign)}>
        <div>
          <img className={styles.logo} alt="logo" src={logoS3Url} />
        </div>

        <div className={styles.main}>
          <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
            {t(`${loginCardT}.title`)}
          </EndUserTypography>

          <Form form={form} onFinish={onFinish} feedbackIcons={undefined}>
            <>
              <FormItem
                {...(error ? inputErrorProps : {})}
                className={styles.formItem}
                hasFeedback={true}
                name={FormItemName.EMAIL}
                rules={[{ required: true, type: 'email', message: t(`${loginFormItems}.email.error`) }]}
              >
                <FormInput label={t(`${loginFormItems}.email.label`)} />
              </FormItem>

              <FormItem
                {...(error ? inputErrorProps : {})}
                className={styles.formItem}
                name={FormItemName.PASSWORD}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t(`${loginFormItems}.password.errorEmpty`),
                  },
                  {
                    pattern: /^.{1,200}$/,
                    message: t(`${loginFormItems}.password.errorLength`),
                  },
                ]}
              >
                <FormInput as={Input.Password} label={t(`${loginFormItems}.password.label`)} />
              </FormItem>

              <EndUserButton className={styles.sendButton} htmlType="submit" type="primary">
                {t(`${loginCardT}.login`)}
              </EndUserButton>
            </>
          </Form>

          <div className={styles.linkButtonsWrapper}>
            <Button className={styles.resetPasswordButton} type="link" onClick={onClickForgotPassword}>
              <EndUserTypography type="headlineEight">{t(`${loginCardT}.forgetPassword`)}</EndUserTypography>
            </Button>
          </div>

          <div className={styles.bottomButtons}>
            <EndUserButton type="secondary" onClick={onClickSignUp}>
              {t(`${loginCardT}.signUp`)}
            </EndUserButton>
            <EndUserButton type="secondary" onClick={onClickContinueAsGuest}>
              {t(`${loginCardT}.asGuest`)}
            </EndUserButton>
          </div>
        </div>
        {youtubeVideoId && (
          <div className={styles.videoFrame}>
            <iframe
              className={styles.iframe}
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title={youtubeVideoTitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>

            <div className={styles.overlay} role="presentation" onClick={handleVideoModalOpen}></div>
          </div>
        )}
        <div className={styles.linkButtonsContainer}>
          <EndUserAuthBottomButtons />
        </div>
      </div>
    </>
  );
};
