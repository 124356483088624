import { FC } from 'react';
import { TenantT } from '../../../../../lib/types/tenants';
import { Form, message } from 'antd';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { Modal } from '../../../../../components/Modal';
import styles from '../index.module.scss';
import { useTranslation } from 'react-i18next';
import { FileUploadForm } from '../../FileUploadForm';
import { useSaveTenantFilesMutation } from '../../../../../lib/store/api/media';

const title = 'pages.tenants.uploadModal.title';

type Props = {
  isOpen: boolean;
  close: () => void;
  tenantData: TenantT | null;
};
export const UploadFileTenantModal: FC<Props> = ({ isOpen, close, tenantData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [updateFiles, { isLoading }] = useSaveTenantFilesMutation();

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        titleProps={{
          title: t(title),
          className: styles.editTitle,
        }}
        width={900}
        cancelButtonProps={{ onClick: close }}
        actionButtonProps={{ onClick: form.submit, loading: isLoading }}
      >
        {isOpen && (
          <FileUploadForm
            form={form}
            tenantData={tenantData}
            onSubmit={updateFiles}
            close={close}
            messageApi={messageApi}
          />
        )}
      </Modal>
    </>
  );
};
