import React, { FC, useEffect } from 'react';
import { Form, FormInstance, Typography } from 'antd';
import { FormItemName } from '../../../../lib/models/Form';

import { FileUploadFormItem } from '../FileUploadFormItem';
import { TenantT } from '../../../../lib/types/tenants';
import { useGetTenantFilesQuery } from '../../../../lib/store/api/media';
import { IconButton } from '../../../../components/IconButton';
import styles from './index.module.scss';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/PlusIcon.svg';
import { Mutation } from '../../../../lib/types/store';
import { TenantUploadFileUpdate } from '../../../../lib/types/common';
import { MessageInstance } from 'antd/es/message/interface';
import { useTranslation } from 'react-i18next';

const uploadModal = 'pages.tenants.uploadModal';
const uploadModalUrlRequired = 'pages.tenants.uploadModal.fileUrlRequired';

const success = `${uploadModal}.success`;
const error = `${uploadModal}.error`;

const { Text } = Typography;

type FormValues = {
  [FormItemName.FILE_UPLOAD_LIST]: {
    [FormItemName.FILE_UPLOAD_ITEM]: string;
    [FormItemName.FILE_UPLOAD_URL]: string;
    id: number;
  }[];
};

type Props = {
  form: FormInstance;
  tenantData: TenantT | null;
  onSubmit: Mutation<TenantUploadFileUpdate>;
  close: () => void;
  messageApi: MessageInstance;
};
export const FileUploadForm: FC<Props> = ({ form, tenantData, onSubmit, close, messageApi }) => {
  const { data: tenantFiles } = useGetTenantFilesQuery(String(tenantData?.id));
  const { t } = useTranslation();

  useEffect(() => {
    if (tenantFiles) {
      if (tenantFiles.length === 0) {
        form.setFieldsValue({
          [FormItemName.FILE_UPLOAD_LIST]: [
            {
              [FormItemName.FILE_UPLOAD_ITEM]: '',
              [FormItemName.FILE_UPLOAD_URL]: null,
            },
          ],
        });
      } else {
        form.setFieldsValue({
          [FormItemName.FILE_UPLOAD_LIST]: tenantFiles.map((item) => ({
            [FormItemName.FILE_UPLOAD_ITEM]: item.name,
            [FormItemName.FILE_UPLOAD_URL]: item.url,
            id: item.id,
          })),
        });
      }
    }
  }, [form, tenantFiles]);

  const onFinish = async (values: FormValues) => {
    const hasNoUrl = values[FormItemName.FILE_UPLOAD_LIST].some(
      (item) => !item[FormItemName.FILE_UPLOAD_URL],
    );

    if (hasNoUrl) {
      messageApi.error(t(uploadModalUrlRequired));
      return;
    }

    const payload = {
      tenantId: String(tenantData?.id),
      fileList: values[FormItemName.FILE_UPLOAD_LIST].map((item) => ({
        name: item[FormItemName.FILE_UPLOAD_ITEM],
        url: item[FormItemName.FILE_UPLOAD_URL],
        id: item.id,
      })),
    };

    try {
      await onSubmit(payload).unwrap();

      close();
      messageApi.success(t(success));
    } catch (err: any) {
      messageApi.error(t(error));
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.List name={FormItemName.FILE_UPLOAD_LIST}>
        {(fields, { add, remove }) => {
          return (
            <div className={styles.listContainer}>
              {fields.map((field) => {
                const url = form.getFieldValue([
                  FormItemName.FILE_UPLOAD_LIST,
                  field.name,
                  FormItemName.FILE_UPLOAD_URL,
                ]);

                return (
                  <FileUploadFormItem
                    remove={remove}
                    tenantId={tenantData?.id}
                    key={field.key}
                    field={field}
                    url={url}
                  />
                );
              })}

              <IconButton
                onClick={() =>
                  add({
                    [FormItemName.FILE_UPLOAD_ITEM]: '',
                  })
                }
                className={styles.addButton}
                icon={
                  <>
                    <PlusIcon className={styles.addIcon} />

                    <Text className={styles.buttonTitle}>add</Text>
                  </>
                }
              />
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
};
