import { FC } from 'react';
import { AddTenantModal } from './AddTenantModal';
import { EditTenantModal } from './EditTenantModal';
import { DeleteTenantModal } from './DeleteTenantModal';
import { useAppSelector, useAppDispatch } from '../../../../lib/store/hooks';
import { ModalActions } from '../../../../lib/models/Modal';
import { closeTenantModal, closeUploadFileModal } from '../../../../lib/store/slices/tenantsSlice';
import { UploadFileTenantModal } from './UploadFileTenantModal';

export const TenantsModals: FC = () => {
  const {
    isModalOpen,
    isUploadModalOpen,
    modalType: currentModalType,
    selectedTenant,
  } = useAppSelector(({ tenants }) => tenants);
  const dispatch = useAppDispatch();

  const close = () => dispatch(closeTenantModal());
  const onCloseUploadFileModal = () => dispatch(closeUploadFileModal());
  const checkIsOpen = (modalType: ModalActions | null) => isModalOpen && modalType === currentModalType;

  const genericProps = { tenantData: selectedTenant, close };
  const uploadTenantGenericProps = { tenantData: selectedTenant, close: onCloseUploadFileModal };

  return (
    <>
      <AddTenantModal {...genericProps} isOpen={checkIsOpen(ModalActions.ADD)} />
      <EditTenantModal {...genericProps} isOpen={checkIsOpen(ModalActions.EDIT)} />
      <DeleteTenantModal {...genericProps} isOpen={checkIsOpen(ModalActions.DELETE)} />
      <UploadFileTenantModal {...uploadTenantGenericProps} isOpen={isUploadModalOpen} />
    </>
  );
};
