import { api, TAGS_KEY } from './';
import { DeleteMediaProps, UploadMediaPayloadT, UploadMediaResponseT } from '../../types/media';
import { createFormData } from '../../utils/helpers';
import { TenantUploadedFile, TenantUploadFileUpdate } from '../../types/common';

export const mediaApi = api.injectEndpoints({
  endpoints: (build) => ({
    fileUpload: build.mutation({
      query: (data: UploadMediaPayloadT) => {
        const formData = createFormData(data);

        return {
          url: '/file/upload',
          method: 'POST',
          body: formData,
          formData: true,
          headers: {
            'X-TENANT': data.tenantId,
          },
          responseHandler: (response) => response.text(),
        };
      },
      transformResponse: (response: string) => {
        if (typeof response === 'string') {
          return response;
        }

        return response;
      },
    }),
    uploadMedia: build.mutation<UploadMediaResponseT, UploadMediaPayloadT>({
      query: (data: UploadMediaPayloadT) => {
        const formData = createFormData(data);

        return {
          url: '/media',
          method: 'POST',
          body: formData,
          formData: true,
          headers: {
            'X-TENANT': data.tenantId,
          },
        };
      },
    }),
    deleteMedia: build.mutation<void, DeleteMediaProps>({
      query: ({ id, tenantId }) => ({
        url: `/media/${id}`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getTenantFiles: build.query<TenantUploadedFile[], unknown>({
      query: (tenantId: string) => ({
        url: `file/`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      providesTags: [TAGS_KEY.FILES],
    }),
    saveTenantFiles: build.mutation<unknown, TenantUploadFileUpdate>({
      query: (data) => {
        return {
          url: 'file/',
          method: 'POST',
          headers: {
            'X-TENANT': String(data.tenantId),
          },
          body: data.fileList,
        };
      },
      invalidatesTags: [TAGS_KEY.FILES],
    }),
  }),
});

export const {
  useUploadMediaMutation,
  useDeleteMediaMutation,
  useFileUploadMutation,
  useGetTenantFilesQuery,
  useSaveTenantFilesMutation,
} = mediaApi;
