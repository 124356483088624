import React, { FC, useState } from 'react';
import styles from '../FileUploadForm/index.module.scss';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import { Button, Form, FormListFieldData, Spin, Tooltip } from 'antd';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { FileUploader } from 'react-drag-drop-files';
import { useFileUploadMutation } from '../../../../lib/store/api/media';
import { IconButton } from '../../../../components/IconButton';

import { ReactComponent as BasketIcon } from '../../../../assets/icons/bakset-icon.svg';
import { useTranslation } from 'react-i18next';

const name = 'pages.tenants.uploadModal.fileName';
const fileNameRequired = 'pages.tenants.uploadModal.fileNameRequired';

type Props = {
  field: FormListFieldData;
  url: string;
  tenantId?: number;
  remove: (fieldName: number) => void;
};

export const FileUploadFormItem: FC<Props> = ({ field, url, tenantId, remove }) => {
  const [copied, setCopied] = useState(false);
  const form = Form.useFormInstance();
  const [uploading, setUploading] = useState(false);

  const { t } = useTranslation();

  const [fileUpload] = useFileUploadMutation();

  const handleUrlChange = (newUrl: string) => {
    const currentList = form.getFieldValue(FormItemName.FILE_UPLOAD_LIST) || [];
    currentList[field.key] = {
      ...currentList[field.key],
      [FormItemName.FILE_UPLOAD_URL]: newUrl,
    };

    form.setFieldValue(FormItemName.FILE_UPLOAD_LIST, currentList);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const onFileUpload = async (fileForUpload: File) => {
    try {
      setUploading(true);

      const result = await fileUpload({
        file: fileForUpload,
        tenantId: String(tenantId),
      }).unwrap();

      if (typeof result === 'string') {
        handleUrlChange(result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const handleChange = (file: File) => {
    onFileUpload(file);
  };

  return (
    <div key={field.key} className={styles.itemContainer}>
      <div className={styles.titleContainer}>
        <FormItem
          name={[field.name, FormItemName.FILE_UPLOAD_ITEM]}
          hasFeedback={true}
          rules={[{ required: true, message: t(fileNameRequired) }, { max: 100 }]}
          className={styles.nameField}
        >
          <FormInput label={t(name)} />
        </FormItem>

        <IconButton onClick={() => remove(field.name)} icon={<BasketIcon />} />
      </div>

      {url && (
        <FormItem className={styles.urlField} name={[field.name, FormItemName.FILE_UPLOAD_URL]}>
          <div className={styles.urlContainer}>
            <span>{url}</span>
            <Tooltip title="Copy">
              <Button
                className={styles.button}
                icon={copied ? <CheckOutlined /> : <CopyOutlined />}
                type="text"
                onClick={() => handleCopy(url)}
              />
            </Tooltip>
          </div>
        </FormItem>
      )}

      <div style={{ position: 'relative' }}>
        <FileUploader multiple={false} handleChange={handleChange} name="file" />

        {uploading && (
          <Spin
            size="small"
            style={{ position: 'absolute', left: '50%', top: 16, transform: 'translate(-50%)' }}
          />
        )}
      </div>
    </div>
  );
};
