import { FC, useState } from 'react';
import { Dropdown, message } from 'antd';
import { IconButton } from '../../IconButton';
import { ActionsIcon } from '../../../assets/icons/ActionsIcon';
import { DropdownMenuButton } from './DropdownMenuButton';
import { useTranslation } from 'react-i18next';
import { useUsersActionsRequests } from '../../../modules/userManagement/hooks/table/useUsersActionsRequests';
import { useTenantsActionsRequest } from '../../../modules/tenants/hooks/useTenantsActionsRequest';
import { useProjectsActionsRequest } from '../../../modules/projects/hooks/useProjectsActionsRequest';

import styles from './index.module.scss';
import { useProductCategoriesActionsRequest } from '../../../modules/productCategories/hooks/useProductCategoriesActionsRequest';
import { useDemandAreaActionsRequest } from '../../../modules/demandAreas/hooks/useDemandAreaActionsRequest';
import { useUpdateBonusCatalogMutation } from '../../../lib/store/api/bonusCatalog';
import { useParams } from 'react-router';
import { setCurrentBonusCatalog, setModalOpened } from '../../../lib/store/slices/bonusCatalogSlice';
import { ModalActions } from '../../../lib/models/Modal';
import { useAppDispatch } from '../../../lib/store/hooks';
import { useRulesActionsRequest } from '../../../modules/ruleConfigurator/hooks/useRulesActionsRequest';
import { useRuleTypesActionsRequest } from '../../../modules/ruleConfigurator/hooks/useRuleTypesActionsRequest';

const actions = 'unique.actions';

const errorText = 'pages.bonusCatalogTab.activation.errorText';

type Props = {
  table: string;
  row: any;
};

export const ActionsDropdown: FC<Props> = ({ table, row }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { id: tenantId, modelId } = useParams();
  const dispatch = useAppDispatch();

  const handleVisibleChange = (flag: boolean) => setVisible(flag);

  const { onUpdateUserStatus, onDeleteUser } = useUsersActionsRequests(messageApi);
  const { onUpdateTenantStatus, onDeleteTenant } = useTenantsActionsRequest(messageApi);

  const { onUpdateProjectStatus, onDeleteProject, isLoadingProductsData, onDownloadProductsData } =
    useProjectsActionsRequest(messageApi);
  const { onUpdateProductCategoryStatus, onDeleteCategory } = useProductCategoriesActionsRequest(messageApi);
  const { onUpdateDemandAreaStatus, onDeleteDemandArea } = useDemandAreaActionsRequest(messageApi);
  const { onDeleteRule, onUpdateRuleStatus } = useRulesActionsRequest(messageApi);
  const { onDeleteRuleType } = useRuleTypesActionsRequest();

  const [updateBonusCatalog] = useUpdateBonusCatalogMutation();
  const downloadAction = async () => {
    await onDownloadProductsData({ projectId: row.id, tenantId: String(tenantId), modelName: row.name });

    setVisible(false);
  };

  const deleteAction = () => {
    setVisible(false);
    if (table === 'tenants') {
      onDeleteTenant(row);
    }
    if (table === 'users') {
      onDeleteUser(row);
    }
    if (table === 'projects') {
      onDeleteProject(row);
    }
    if (table === 'demandAreas') {
      onDeleteDemandArea(row);
    }
    if (table === 'productCategories') {
      onDeleteCategory(row);
    }
    if (table === 'bonuses') {
      dispatch(setCurrentBonusCatalog(row));
      dispatch(setModalOpened(ModalActions.DELETE));
    }
    if (table === 'rules') {
      onDeleteRule(row);
    }
    if (table === 'ruleTypes') {
      onDeleteRuleType(row);
    }
  };

  const toggleStatus = async () => {
    setVisible(false);
    if (table === 'users') {
      await onUpdateUserStatus(row);
    }
    if (table === 'tenants') {
      await onUpdateTenantStatus(row);
    }

    if (table === 'projects') {
      await onUpdateProjectStatus(row);
    }

    if (table === 'demandAreas') {
      await onUpdateDemandAreaStatus(row);
    }

    if (table === 'productCategories') {
      await onUpdateProductCategoryStatus(row);
    }

    if (table === 'bonuses') {
      try {
        await updateBonusCatalog({
          activated: !row.activated,
          tenantId,
          id: row.id,
          projectId: Number(modelId),
          title: row.title,
          description: row.description,
          //eslint-disable-next-line
        } as any).unwrap();
        //eslint-disable-next-line
      } catch (e: any) {
        let message = e.data?.message;
        if (e.data.exceptionCode === 'ERROR_010') {
          message = t(errorText);
        }

        messageApi.error(message);
      }
    }
    if (table === 'rules') {
      onUpdateRuleStatus(row);
    }
  };

  const disabledDeactivate = {
    tenants: !row.activated,
    projects: !row.activated,
    productCategories: !row.activated,
    demandAreas: !row.activated,
    users: ['DEACTIVATED', 'PENDING'].includes(row.status),
    bonuses: !row.activated,
    rules: !row.activated,
    ruleTypes: true,
  };

  const disabledActive = {
    tenants: row.activated,
    projects: row.activated,
    productCategories: row.activated,
    demandAreas: row.activated,
    users: ['ACTIVE', 'PENDING'].includes(row.status),
    bonuses: row.activated,
    rules: row.activated,
    ruleTypes: true,
  };

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement="topRight"
      onOpenChange={handleVisibleChange}
      dropdownRender={() => (
        <div className={styles.dropdownRender}>
          {contextHolder}
          <DropdownMenuButton
            text={t(`${actions}.activate`)}
            disabled={disabledActive[table as keyof typeof disabledActive]}
            onClick={toggleStatus}
          />
          <DropdownMenuButton
            text={t(`${actions}.deactivate`)}
            disabled={disabledDeactivate[table as keyof typeof disabledDeactivate]}
            onClick={toggleStatus}
          />

          {table === 'projects' && (
            <DropdownMenuButton
              text={t(`${actions}.downloadUserReport`)}
              onClick={downloadAction}
              isLoading={isLoadingProductsData}
            />
          )}
          <DropdownMenuButton text={t(`${actions}.delete`)} danger onClick={deleteAction} />
        </div>
      )}
    >
      <IconButton icon={<ActionsIcon />} />
    </Dropdown>
  );
};
