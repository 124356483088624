import { UploadResponseT } from './entities';

export type UploadMediaPayloadT = {
  file: File;
  targetType?: string;
  targetId?: string;
  originalFileName?: string;
  tenantId?: string;
};

export type UploadMediaResponseT = UploadResponseT;

export type DeleteMediaProps = { id: number; tenantId?: string };

export enum TargetType {
  GS_FAVICON = 'GS_FAVICON',
  TENANT_LOGO = 'TENANT_LOGO',
  LOGO = 'LOGO',
  PICTURES = 'PICTURES',
  ICONS = 'ICONS',
  FILE = 'FILE',
}

export enum MIMEType {
  SVG = 'image/svg+xml',
  FAVICON = 'image/x-icon',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PICTURE = `image/png, image/jpeg`,
  ALL_IMAGES = 'image/*',
  SVG_PNG_JPEG = 'image/svg+xml, image/png, image/jpeg',
}
