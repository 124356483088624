import { ElementType, forwardRef, useMemo, useState } from 'react';
import { Input, InputProps } from 'antd';
import { InputLabel } from '../InputLabel';
import { isLength } from '../../lib/utils/helpers';
import cx from 'classnames';

import styles from './index.module.scss';

type Props = {
  label?: string;
  className?: string;
  hasFeedback?: boolean;
  value?: string;
  as?: ElementType;
  isActive?: boolean;
  icon?: JSX.Element;
  rootClassname?: string;
  labelClass?: string;
  autoSize?: {
    minRows: number;
    maxRows: number;
  };
} & InputProps;

export const FormInput = forwardRef<HTMLDivElement, Props>(
  (
    { value = '', label, as, rootClassname, labelClass, isActive, size, autoSize, className, icon, ...rest },
    ref,
  ) => {
    const Component = as || Input;
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = () => setIsFocused(true);
    const onBlur = () => setIsFocused(false);

    const asLabel = isActive || isFocused || isLength(value) || ![undefined, null, ''].includes(value);
    const inputProps = { onFocus, onBlur, value, autoSize, size, ...rest };

    const labelClassName = useMemo(
      () => cx(asLabel ? 'asLabel' : '', icon ? styles.labelWithLeftIcon : '', !asLabel ? labelClass : ''),
      [asLabel, icon, labelClass],
    );

    const isInvalid = inputProps['aria-invalid'];

    return (
      <div ref={ref} className={cx(styles.root, rootClassname && rootClassname)}>
        {icon}
        <Component
          className={cx(styles.input, className, {
            [styles.smallInput]: size === 'small',
            [styles.disabled]: rest?.disabled,
          })}
          {...inputProps}
          status={isInvalid ? 'error' : ''}
          style={{ fontWeight: 'bold' }}
        />
        {label && <InputLabel text={label} size={size} className={labelClassName} />}
      </div>
    );
  },
);

FormInput.displayName = 'FormInput';
