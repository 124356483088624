import React, { FC, useState } from 'react';
import { Footer as AntFooter } from 'antd/es/layout/layout';
import { FooterButtonT, FooterConfigT, FooterOperation } from '../../../../modules/designSettings/types';
import { useAppSelector } from '../../../../lib/store/hooks';
import cx from 'classnames';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useCheckStatusBarVisibility } from '../../../../lib/hooks/endUserHooks/useCheckStatusBarVisibility';
import { openLinkAndTrack, sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { TrackingSettingsModal } from './modals/TrackingSettingsModal';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { Button } from '../../../../components/Button';
import { EndUserContactFormModal } from '../../../../modules/endUserContactForm/components/ContactFormModal';

const translation = 'endUser.shared.dataButton';

type Props = {
  footerConfig: FooterConfigT;
};

export const Footer: FC<Props> = ({ footerConfig }) => {
  const { isStatusBarHidden } = useCheckStatusBarVisibility();
  const [isTrackerSettingsVisible, setIsTrackingSettingsVisible] = useState(false);
  const { t } = useTranslation();

  const { isBackgroundShadow, isFooterOn, backgroundColor, buttonLinks } = footerConfig;
  const { brandKit } = useAppSelector(({ endUser }) => endUser);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  if (!isFooterOn) {
    return null;
  }

  const handleLinkClick = (index: number) => {
    switch (index) {
      case 1:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Status auf');
        break;
      case 2:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Informationen zum Datenschutz auf');
        break;
      case 3:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User nimmt Kontakt zu energie schwaben auf');
        break;
      case 4:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Informationen zum Impressum auf');
        break;
    }
  };

  const handleTrackerSettingsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsTrackingSettingsVisible(true);

    sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Informationen zur Datenverwendung auf');
  };

  const handleTrackerSettingsClose = () => {
    setIsTrackingSettingsVisible(false);

    sendDataToAnalytics(`Close tracker settings modal`);
  };

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const onLinkClick = (index: number, footerItem: FooterButtonT) => {
    handleLinkClick(index + 1);

    if (footerItem?.operation?.value === FooterOperation.FORM) {
      handleOpenContactModal();
      return;
    }

    const link = footerItem.linkUrl;
    openLinkAndTrack(link);
  };

  return (
    <>
      <AntFooter
        className={cx(styles.wrapper, {
          [styles.withShadow]: isBackgroundShadow,
          [styles.withoutFixedBar]: isStatusBarHidden,
        })}
        style={{
          backgroundColor: brandKit?.colors[backgroundColor],
        }}
      >
        <div className={styles.buttonLinks}>
          {buttonLinks &&
            buttonLinks?.map((item, index) => (
              <Button
                style={{ minHeight: '100%' }}
                type="text"
                onClick={() => onLinkClick(index, item)}
                key={index}
              >
                <EndUserTypography type="headlineFifth" component="Text" className={styles.text}>
                  {item.linkName}
                </EndUserTypography>
              </Button>
            ))}
          <Button style={{ minHeight: '100%' }} type="text" onClick={handleTrackerSettingsClick}>
            <EndUserTypography type="headlineFifth" component="Text" className={styles.text}>
              {t(translation)}
            </EndUserTypography>
          </Button>
        </div>
      </AntFooter>
      <TrackingSettingsModal isModalOpen={isTrackerSettingsVisible} close={handleTrackerSettingsClose} />
      <EndUserContactFormModal
        products={[]}
        isOpen={isContactModalOpen}
        onClose={handleCloseContactModal}
        fromFooter
      />
    </>
  );
};
