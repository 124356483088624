export const EMPTY_LENGTH = 0;

export const ACCESS_TOKEN_NAME = 'accessToken';
export const REFRESH_TOKEN_NAME = 'refreshToken';

export const END_USER_HARDCODED_DOMAIN = 'energie-schwaben-meinzuhause.de';
export const END_USER_HARDCODED_MODEL_URL = 'https://energie-schwaben.hausversorger.de';
export const END_USER_FRENCH_TENANT_MODEL_NAME = 'ModelForDemoCompany';
export const END_USER_ENERGIE_SCHWABEN_MODEL_NAME = 'Mein Zuhause';

export const END_SUER_STADTWERK_TAUBERFRANKEN_NAME = 'Stadtwerk Tauberfranken';

export const DEFAULT_PAGINATION = {
  current: undefined,
  pageSize: undefined,
  total: undefined,
};

export const FIRST_REQUEST_PAGE = 0;

export const PAGINATION_ELEMENTS_AMOUNT = 10;

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const SORT_ORDERS = Object.values(SortOrder);

export enum TableSortOrder {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export const WITHOUT_PAGINATION_ELEMENTS_SIZE = 10000;

export enum GLOBAL_SETTINGS_NAMES {
  GENERAL_SETTINGS = 'GENERAL_SETTINGS',
  DESIGN_SETTINGS = 'DESIGN_SETTINGS',
  DESIGN_SETTINGS_BRAND_KIT = 'DESIGN_SETTINGS_BRAND_KIT',
  PRODUCTS = 'PRODUCTS',
  STATUS_LEVEL = 'STATUS_LEVEL',
  RULE_BUILDER = 'RULE_BUILDER',
  FORMS = 'FORMS',
  PAGE_ARCHITECTURE = 'PAGE_ARCHITECTURE',
}

export enum EXCEPTION_CODE {
  ERROR_007 = 'ERROR_007',
  ERROR_030 = 'ERROR_030',
}

export const CONTAINER_QUERIES = {
  isMobile: {
    maxWidth: 599,
  },
  isLargerMobile: {
    minWidth: 600,
  },
  isTablet: {
    maxWidth: 900,
  },
  isOnlyTablet: {
    minWidth: 600,
    maxWidth: 1200,
  },
  isLargerTablet: {
    minWidth: 900,
  },
  isLargerSpecificTablet: {
    minWidth: 1136,
  }, // TODO mb we can delete isLargerSpecificTablet, isOnlySpecificTablet
  isOnlySpecificTablet: {
    minWidth: 600,
    maxWidth: 1135,
  },
  isLargeTablet: {
    minWidth: 901,
    maxWidth: 1199,
  },
  isDesktop: {
    maxWidth: 1200,
  },
  isLargerDesktop: {
    minWidth: 1200,
  },
  isSmallSpecificTablet: {
    maxWidth: 730,
  },
  isMoreThanFullHd: {
    minWidth: 1921,
  },
};
